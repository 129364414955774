<template>
    <div>
       <div v-if="isDivision">
            <DivisionDashboard></DivisionDashboard>
 </div>
        <v-content v-else>
            <v-breadcrumbs :items="items" large></v-breadcrumbs>
            <v-row>
                <v-col cols="12">
                    <v-row justify="start">
                        <v-col
                                cols="auto"
                                v-for="title in titles"
                                v-bind:key="title.link"
                                class="viewCard"
                        >
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <router-link :to="title.link">
                                        <v-card
                                                :elevation="10"
                                                class="blue darken-1 text-center"
                                                height="100"
                                                width="200"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content>
                                                    <v-list-item-title
                                                            class="white--text subtitle-1 mt-1 pa-4"
                                                    >{{title.name}}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle class="white--text header-6 mt-1">
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </router-link>
                                </template>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-container>
                <v-row>
                    <v-col cols="12" md="6">
                        <h1 v-if="!isNepaliLanguage"
                            class="headline mb-2 mt-5 primary--text"
                            style="border-bottom: 1px solid grey">
                            Employees
                        </h1>
                        <v-card>
                            <v-list style="background-color: #1976d2">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-title id="table" class="white--text"><strong>{{ $t("s_no") }}</strong></v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title id="table"
                                                           align="center"
                                                           class="white--text">
                                            <strong>{{ $t("employee_name") }}</strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="mr-18 white--text"><strong>{{ $t("email") }}</strong></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="white--text">
                                            <strong>
                                                {{$t("position")}}
                                            </strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title id="table" class="white--text">
                                            <strong>
                                                {{$t("contact_no")}}
                                            </strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card style="overflow-y: scroll; height: 350px">
                            <v-card style="height: 350px;">
                                <v-list v-for="(hotelEmployee, i) in hotelEmployeeDetails"
                                        :key="i">
                                    <v-list-item :style="{ background: hotelEmployee.backgroundColor }">
                                        <v-list-item-avatar>
                                            <v-title id="table" class="black--text">
                                                {{i + 1}}
                                            </v-title>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title id="table"
                                                               align="center"
                                                               class="black--text">{{ hotelEmployee.empName }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table"
                                                               class="mr-18 black--text">{{ hotelEmployee.empEmail }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class="black--text">
                                                {{ hotelEmployee.empType }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="right">
                                            <v-list-item-title id="table" class="black--text">
                                                {{hotelEmployee.empPhone}}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </v-list>
                            </v-card>
                        </v-card>
                        <!--<button @click="$router.push('/hotelEmployee')" class=" white--text primary align-end" dark style="padding:5px; border-radius:5px; margin-top:10px;">For More Details</button>-->
                    </v-col>
                    <!--<v-col cols="12" md="6" class="mt-5">
                        <h1 class="headline mb-2 primary--text"
                            style="border-bottom: 1px solid grey">
                            Gross Foreign Exchange Earnings from Tourism
                        </h1>
                        <v-card elevation="5">
                            <template>
                                <div id="app">
                                    <GChart type="LineChart"
                                            :data="barsDat"
                                            :options="chartOptions"
                                            style="height: 420px;" />
                                </div>
                            </template>
                        </v-card>
                    </v-col>-->



                    <!--<v-col cols="12" md="6">
                        <h1 v-if="!isNepaliLanguage"
                            class="headline mb-2 mt-5 primary--text">
                            Sector
                        </h1>
                        <v-card>
                            <v-list style="background-color: #1976d2">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-title id="table" class="white--text"><strong>{{ $t("s_no") }}</strong></v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title id="table"
                                                           align="center"
                                                           class="white--text">
                                            <strong>{{ $t("employee_name") }}</strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="mr-18 white--text"><strong>{{ $t("email") }}</strong></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="white--text">
                                            <strong>
                                                {{$t("position")}}
                                            </strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title id="table" class="white--text">
                                            <strong>
                                                {{$t("contact_no")}}
                                            </strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card style="overflow-y: scroll; height: 350px">
                            <v-card style="height: 350px;">
                                <v-list v-for="(hotelEmployee, i) in hotelEmployeeDetails"
                                        :key="i">
                                    <v-list-item :style="{ background: hotelEmployee.backgroundColor }">
                                        <v-list-item-avatar>
                                            <v-title id="table" class="black--text">
                                                {{i + 1}}
                                            </v-title>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title id="table"
                                                               align="center"
                                                               class="black--text">{{ hotelEmployee.empName }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table"
                                                               class="mr-18 black--text">{{ hotelEmployee.empEmail }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class="black--text">
                                                {{ hotelEmployee.empType }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="right">
                                            <v-list-item-title id="table" class="black--text">
                                                {{hotelEmployee.empPhone}}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </v-list>
                            </v-card>
                        </v-card>-->
                        <!--<button @click="$router.push('/hotelEmployee')" class=" white--text primary align-end" dark style="padding:5px; border-radius:5px; margin-top:10px;">For More Details</button>-->
                    <!--</v-col>-->
                </v-row>
            </v-container>
        </v-content>
    </div>
</template>

<script>
    import axios from "axios";
    import {mapGetters} from "vuex";
    import DivisionDashboard from '../../dashboard/DivisionDashboard';
    import publicAxios from "../../../simple-axios"
    export default {
        name: "Dashboard",
        watch: {},
        components: {DivisionDashboard},
        computed: {
            ...mapGetters(['getSystemUserData']),
            isDivision() {
                return this.getSystemUserData.role === 'Division'
            },
            items() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: true,
                        to: "/dashboard",
                        exact: true
                    }
                ]
            },
            titles() {
                return [
                    {
                        name: this.$t('dashboard'),
                        link: "/branch"
                    },
                    {
                        name: this.$t('royalty_received'),
                        link: "/NMAList"
                    },
                    {
                        name: this.$t('employee'),
                        link: "/NMAEmployee"
                    }
                ]
            }
        },
        data() {
            return {
                hotelEmployeeDetails: [],
                barsDat: [],
                chartOptions: {
                    title: 'Company Performance',
                    curveType: 'function',
                },
                branchInformation: [],
                roomCount: "",
           
            }
        },
        mounted() {
            this.formData()
            this.getLineChart()
            this.getEmployeeDetails()
        },
        methods: {
            async formData() {
                const dashboardData = await axios.get(
                    "Branch/GetBranchInformationByBranchID"
                );
                this.branchInformation = dashboardData.data;

                const availableCount = await axios.get('Hotel/RoomStatusCountAsync', {
                    params: {
                        ShowCount: true
                    }
                })
                this.roomCount = availableCount.data
            },
            async getLineChart() {
                const { data } = await axios.post('NRBExchange/GetGrossExchangeEarningsGraphAsync', {})
                let formatedData = this.getChartData(data)
                this.barsDat = formatedData
            },
            getChartData(data) {
                console.log(data)
                let arr = []
                arr[0] = Object.keys(data[0])
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1])
                }
                return arr;
            },
            async getEmployeeDetails() {
                const { data } = await publicAxios.post(
                    "NRBExchange/GetEmployeeDetails/0"
                );
                this.hotelEmployeeDetails = data;
                console.log("this.hotelEmployeeDetails", this.hotelEmployeeDetails);
            },
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>